import { Controller } from "@hotwired/stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  beforeReflex (element, reflex) {
    document.body.classList.add('wait')
    document.querySelector(".page-spinner").style.visibility = 'visible'

    console.log( "beforeReflex raised" )
    
    console.log( element )

    this.benchmark = performance.now()
  }

  reflexSuccess (element, reflex, error) {
    // show success message etc...
    console.log( "reflexSuccess raised!" )
  }

  reflexError (element, reflex, error) {

    document.querySelector(".page-spinner").style.visibility = 'hidden'

    console.log( "reflexError raised" )
  }
  
  afterReflex (element, reflex) {
    console.debug(
      reflex,
      `${(performance.now() - this.benchmark).toFixed(0)}ms`
    )

    console.log( "afterReflex raised" )

    document.body.classList.remove('wait')
    document.querySelector(".page-spinner").style.visibility = 'hidden'

    const focusElement = this.element.querySelector('[autofocus]')
    if (focusElement) {
      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

}
