import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

/* This is the custom StimulusReflex controller for MachineServicesLogReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = ['serial_number', 'machine_model', 'machine_models_service','created_at']

  connect(){
    StimulusReflex.register(this)
  }

  search(){
    this.stimulate("MachineServicesLogReflex#search",this.getParams )
  }
  
  get getParams () {
    return  {
      serial_number: this.serial_numberTarget.value,
      machine_model: this.machine_modelTarget.value,
      machine_models_service: this.machine_models_serviceTarget.value,
      created_at: this.created_atTarget.value
    }
  }

}
