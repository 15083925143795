import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'


export default class extends ApplicationController {
 
  static targets = ['serial_number','id_machine_model','id_company_owner', 'customer_register_at','manufactured_at', 'submit']

  connect(){
    StimulusReflex.register(this)
  }

  search(){
    this.stimulate("MachineReflex#search",this.getParams )
  }

  get getParams () {
    return  {
      serial_number: this.serial_numberTarget.value,
      id_machine_model: this.id_machine_modelTarget.value,
      id_company_owner: this.id_company_ownerTarget.value,
      customer_register_at: this.customer_register_atTarget.value,
      manufactured_at: this.manufactured_atTarget.value
    }
  }

}
