import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for ServiceScheduleMachineReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /* Reflex specific lifecycle methods.
   * Use methods similar to this example to handle lifecycle concerns for a specific Reflex method.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Example:
   *
   *   <a href="#" data-reflex="ServiceScheduleMachineReflex#example">Example</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ServiceScheduleMachineReflex#example"
   *
   *   error - error message from the server
   */



  reflexSuccess (element) {
    // show success message etc...
    var service_type = element.options[element.selectedIndex].text;
    var div = document.getElementById('machine-delivery-fields');

    if(service_type == 'Salida de máquina'){
      div.classList.remove('hide');
    }
    else{
      div.classList.add('hide');
    }
  }

  
  // updateSuccess(element, reflex) {
  //   element.innerText = 'Updated Successfully.'
  // }

  // updateError(element, reflex, error) {
  //   console.error('updateError', error);
  //   element.innerText = 'Update Failed!'
  // }

}
