import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
//import { debounce } from 'lodash-es'

/* This is the custom StimulusReflex controller for MachineGuestreportReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = [ 
    'id',
    'guest_report_status',
    'created_at_begin',
    'created_at_end',
    'admin',
    'company_customer' 
  ]

  connect(){
    super.connect()
    //this.perform = debounce(this._perform, 350).bind(this)
  }

  search(){
    this.stimulate( "MachineGuestReportReflex#search",this.getParams )
  }
  
  get getParams () {
    return  {
      admin: this.adminTarget.value,
      company_customer: this.company_customerTarget.value,
      guest_report_status: this.guest_report_statusTarget.value,
      created_at_begin: this.created_at_beginTarget.value,
      created_at_end: this.created_at_endTarget.value,
      id: this.idTarget.value
    }
  }

}
