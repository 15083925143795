// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
import "cocoon-js";
//import "cocoon";
//= require nprogress
//= require nprogress-turbolinks

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

// CONTROLLERS
import "controllers"

// FLAT PICKR
import flatpickr from "flatpickr"
import { Spanish } from "flatpickr/dist/l10n/es.js"

import 'flatpickr/dist/flatpickr.css';

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.addEventListener('DOMContentLoaded', () => {
  $('.select2').select2();
})

import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css'

document.addEventListener('DOMContentLoaded', function() {
    flatpickr('.datetime-picker', {
        "locale": Spanish,
        enableTime: true,
        altInput: true,
        altFormat: "F j, Y at h:i K",
        dateFormat: "Y-m-d H:i",
        onReady: function(dateObj, dateStr, instance) {
          $('.flatpickr-calendar').each(function() {
              var $this = $(this);
              if ($this.find('.flatpickr-clear').length < 1) {
                  $this.append('<div class="flatpickr-clear" style="padding:10px;">Limpiar</div>');
                  $this.find('.flatpickr-clear').on('click', function() {
                      instance.clear();
                      instance.close();
                  });
              }
          });
        }
    });
    flatpickr('.date-picker', {
      "locale": Spanish,
      enableTime: false,
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      onReady: function(dateObj, dateStr, instance) {
        $('.flatpickr-calendar').each(function() {
            var $this = $(this);
            if ($this.find('.flatpickr-clear').length < 1) {
                $this.append('<div class="flatpickr-clear" style="padding:10px;">Limpiar</div>');
                $this.find('.flatpickr-clear').on('click', function() {
                    instance.clear();
                    instance.close();
                });
            }
        });
      }
  });

});

require("chartkick")
require("chart.js")

