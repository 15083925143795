// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import Lightbox from 'stimulus-lightbox'
import lgZoom from 'lightgallery/plugins/zoom'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application)
application.register('lightbox', Lightbox)