import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
import $ from 'jquery';

/* This is the custom StimulusReflex controller for PolicyTypeReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ "source", "target" ];
  
  connect(){
    StimulusReflex.register(this)
  }

  search(){
    this.stimulate("PolicyTypeReflex#search",this.getParams )
  }

  get getParams () {
    return  {
      id_machine_model: this.sourceTarget.value
    }
  }

  afterReflex (element, reflex) {
    $('.select2').select2();
    document.body.classList.remove('wait')
    document.querySelector(".page-spinner").style.visibility = 'hidden'

    const focusElement = this.element.querySelector('[autofocus]')
    if (focusElement) {
      focusElement.focus()

      // shenanigans to ensure that the cursor is placed at the end of the existing value
      const value = focusElement.value
      focusElement.value = ''
      focusElement.value = value
    }
  }

}
