import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

/* This is the custom StimulusReflex controller for CompanyReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  

  static targets = ['rfc', 'business_name', 'tradename', 'email', 'submit']

  connect(){
    super.connect()
  }

  search(){
    this.stimulate("CompanyReflex#search",this.getParams )
  }

  get getParams () {
    return  {
      rfc: this.rfcTarget.value,
      business_name: this.business_nameTarget.value,
      tradename: this.tradenameTarget.value,
      email: this.emailTarget.value
    }
  }
}
