import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [ 'created_at_begin', 'created_at_end', 'service_schedule', 'submit' ]

  connect(){
    StimulusReflex.register(this)
  }

  search(){
    this.stimulate( "SurveyanswerReflex#search",this.getParams )
  }

  get getParams () {
    return  {
      created_at_begin: this.created_at_beginTarget.value,
      created_at_end: this.created_at_endTarget.value,
      service_schedule: this.service_scheduleTarget.value
    }
  }
  
}
