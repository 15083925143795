import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

/* This is the custom StimulusReflex controller for AuditReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = [ 
    'table', 
    'admin', 
    'submit', 
    'created_at_begin', 
    'created_at_end', 
    'auditable_id' 
  ]

  connect(){
    StimulusReflex.register(this)
  }

  search(){
    this.stimulate("AuditReflex#search",this.getParams )
  }

  get getParams () {
    return  {
      table: this.tableTarget.value,
      admin: this.adminTarget.value,
      created_at_begin: this.created_at_beginTarget.value,
      created_at_end: this.created_at_endTarget.value,
      auditable_id: this.auditable_idTarget.value
    }
  }

  _perform (event) {
    event.preventDefault()
    this.stimulate( 'AuditReflex#search', this.getParams )
  }


}
