import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
//import { debounce } from 'lodash-es'

/* This is the custom StimulusReflex controller for ServiceScheduleReflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  static targets = [ 
    'id', 
    'schedule_status',
    'technician', 
    'company_customer', 
    'scheduled_datetime_begin', 
    'scheduled_datetime_end',
    'has_delivery_machines'
  ]

  connect(){
    super.connect()
    //this.perform = debounce(this._perform, 350).bind(this)
  }

  search(){
    this.stimulate( "ServiceScheduleReflex#search",this.getParams )
  }
  
  get getParams () {
    return  {
      technician: this.technicianTarget.value,
      company_customer: this.company_customerTarget.value,
      schedule_status: this.schedule_statusTarget.value,
      scheduled_datetime_begin: this.scheduled_datetime_beginTarget.value,
      scheduled_datetime_end: this.scheduled_datetime_endTarget.value,
      has_delivery_machines: this.has_delivery_machinesTarget.checked,
      id: this.idTarget.value
    }
  }

}
